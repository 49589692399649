import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
import './CardSlider.css'; // Custom CSS for styling

const CardSlider2 = () => {
   
    const cards = [
        { id: 1, image: "assets/HomeIimages/certificate/6.png", alt: "Microsoft", title: "Microsoft", description: "Security, Speciality" },
        { id: 2, image: "assets/HomeIimages/certificate/5.png", alt: "Second item", title: "Microsoft", description: "Offering technology" },
        //{ id: 3, image: "assets/HomeIimages/certificate/4.png", alt: "Third item", title: "Microsoft", description: "Security, Speciality" },
        //{ id: 4, image: "assets/HomeIimages/certificate/3.png", alt: "Fourth item", title: "Microsoft", description: "Security, Speciality" },
        //{ id: 5, image: "assets/HomeIimages/certificate/2.png", alt: "Fifth item", title: "Microsoft", description: "Security, Speciality" },
        // { id: 6,image: "assets/HomeIimages/certificate/1.png", alt: "Sixth item", title: "Microsoft", description: "Security, Speciality" },
    ];

    // Settings for the Slider
    console.log("windowSize-----1----->");
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '50px',
    };

    return (
        <div className="card-slider-container">
            <Slider {...settings}>
                {cards.map(card => (
                    <div key={card.id} className="card-item">
                        <div className="card-content">
                            <img src={card.image} alt={card.title} />
                                <h3 style={{ textAlign: "center" }}>{card.title}</h3>
                                <p style={{ textAlign: "center" }}>{card.description}</p>
                                </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default CardSlider2;
