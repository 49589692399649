import React, { Fragment, useEffect, useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';


function Sliderimg() {
    return (
        <Carousel data-bs-theme="dark">
            <Carousel.Item>
                <div className="row" style={{ padding: 0, margin: 0 }}>
                    <div
                        className=" col-md-7 First_slide_Text"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <div className="images">
                            <img src="assets/HomeIimages/Home_Images/Group 1000003595 (2).png" />
                        </div>
                        <div className="text_section_slide">
                            <h1 style={{ color: "rgb(171, 97, 236)" }}>Digital Marketing </h1>
                            <h3> <strong>Services</strong></h3>
                            <div className="section_ranked">
                                <div>
                                    <h1 style={{ color: "rgb(171, 97, 236)" }}>#1</h1>
                                </div>
                                <div className="text_ranked">
                                    <p>Ranked as #1 Top</p>
                                    <p>Digital Marketing Service In Delhi Ncr</p>
                                    
                                </div>
                            </div>
                            <div className="buttons">
                                <button style={{ background: "rgb(171, 97, 236)" }}>
                                    Get Quotes
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-md-5 first_slide_images"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <img
                            src="assets/HomeIimages/Home_Images/Desktop - 14@0.5x.png"
                            alt=""
                            className="desktop-images"
                        />
                        <img
                            src="assets/HomeIimages/Home_Images/Desktop - 16@0.33x.png"
                            alt=""
                            className="images_mobile"
                        />
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="row" style={{ padding: 0, margin: 0 }}>
                    <div
                        className="col-lg-7 First_slide_Text"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <div className="images">
                            <img src="assets/HomeIimages/Home_Images/Group 1000003595 (1).png" />
                        </div>
                        <div className="text_section_slide">
                            <h1 style={{ color: "#01C8FE" }}>Website</h1>
                            <h3>Development Company</h3>
                            <div className="section_ranked">
                                <div>
                                    <h1 style={{ color: "#01C8FE" }}>#1</h1>
                                </div>
                                <div className="text_ranked">
                                    <p>Ranked as #1 Top</p>
                                    <p>Website Development Service In Delhi Ncr</p>
                                    
                                </div>
                            </div>
                            <div className="buttons">
                                <button style={{ background: "#01C8FE" }}>Get Quotes</button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-5 first_slide_images"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <img
                            src="assets/HomeIimages/Home_Images/Desktop - 15@0.5x.png"
                            alt=""
                            className="desktop-images"
                        />
                        <img
                            src="assets/HomeIimages/Home_Images/Desktop - 17@0.33x.png"
                            alt=""
                            className="images_mobile"
                        />
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="row" style={{ padding: 0, margin: 0 }}>
                    <div
                        className="col-lg-7 First_slide_Text"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <div className="images">
                            <img src="assets/HomeIimages/Home_Images/Desktop - 13.png" />
                        </div>
                        <div className="text_section_slide">
                            <h1 style={{ color: "#f54441" }}>Customized Mobile APP</h1>
                            <h3 style={{ color: "rgb(139, 33, 33)" }}>Development Company</h3>
                            <div className="section_ranked">
                                <div>
                                    <h1 style={{ color: "#f54441" }}>#1</h1>
                                </div>
                                <div className="text_ranked">
                                    <p>Ranked as #1 Top</p>
                                    <p>App Development Company In Delhi Ncr</p>
                                    
                                </div>
                            </div>
                            <div className="buttons">
                                <button style={{ background: "#f54441" }}>Get Quotes</button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-5 first_slide_images"
                        style={{ paddingTop: 60, margin: 0 }}
                    >
                        <img
                            src="assets/HomeIimages/Home_Images/ecommerce-development-image.webp"
                            alt=""
                            className="desktop-images"
                        />
                        <img
                            src="assets/HomeIimages/Home_Images/ecommerce-development-image.webp"
                            alt=""
                            className="images_mobile"
                        />
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="row" style={{ padding: 0, margin: 0 }}>
                    <div
                        className="col-lg-7 First_slide_Text"
                        style={{ paddingTop: 60, margin: 0 }}
                    >
                        <div className="images">
                            <img src="assets/HomeIimages/Home_Images/Desktop - 23.png" />
                        </div>
                        <div className="text_section_slide">
                            <h1 style={{ color: "#fe0166" }}>Mobile APP</h1>
                            <h3 style={{ color: "#681234" }}>Development Company</h3>
                            <div className="section_ranked">
                                <div>
                                    <h1 style={{ color: "#fe0166" }}>#1</h1>
                                </div>
                                <div className="text_ranked">
                                    <p>Ranked as #1 Top</p>
                                    <p>Mobile App Development Company In Rohini .</p>
                                    
                                </div>
                            </div>
                            <div className="buttons">
                                <button style={{ background: "#fe0166" }}>Get Quotes</button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-5 first_slide_images"
                        style={{ paddingTop: 60, margin: 0 }}
                    >
                        <img
                            src="assets/HomeIimages/Home_Images/mobile-app-development-image.webp"
                            alt=""
                            className="desktop-images"
                        />
                        <img
                            src="assets/HomeIimages/Home_Images/mobile-app-development-image.webp"
                            alt=""
                            className="images_mobile"
                        />
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="row" style={{ padding: 0, margin: 0 }}>
                    <div
                        className="col-lg-7 First_slide_Text"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <div className="images">
                            <img src="assets/HomeIimages/Home_Images/Desktop - 24.png" />
                        </div>
                        <div className="text_section_slide">
                            <h1 style={{ color: "#19ddd3" }}>Full-stack</h1>
                            <h3 style={{ color: "#0c4488" }}>Development Company</h3>
                            <div className="section_ranked">
                                <div>
                                    <h1 style={{ color: "#19ddd3" }}>#1</h1>
                                </div>
                                <div className="text_ranked">
                                    <p>Ranked as #1 Top</p>
                                    <p>App Development Company In Delhi Ncr .</p>
                                    
                                </div>
                            </div>
                            <div className="buttons">
                                <button style={{ background: "#19ddd3" }}>Get Quotes</button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-5 first_slide_images"
                        style={{ padding: 0, margin: 0 }}
                    >
                        <img
                            src="assets/HomeIimages/Home_Images/full-stack-development-image.webp"
                            alt=""
                            className="desktop-images"
                        />
                        <img
                            src="assets/HomeIimages/Home_Images/full-stack-development-image.webp"
                            alt=""
                            className="images_mobile"
                        />
                    </div>
                </div>
            </Carousel.Item>
        </Carousel>
    );
};
export default Sliderimg;