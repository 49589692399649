import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer";
import { getBaseUrl } from "./../utils";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './aboutus.css';


function PrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1200, // Customize the duration as needed
    });
  }, []);
  return (
    <Fragment>
        <>
          <Navbar />
         
          <>
  <section
    className="d-flex align-items-center page-hero  inner-page-hero "
    id="page-hero"
    style={{ backgroundImage: 'url("assets/assets/images/solutionbanner.png")' }}
  >
    <div
      className="overlay-photo-image-bg parallax"
      data-bg-img="https://www.reptileindia.co.in/laravel_website/public/assets/images/hero/inner-page-hero.jpg"
      data-bg-opacity={1}
    />
    <div className="overlay-color" data-bg-opacity=".75" />
    <div className="container">
      <div className="hero-text-area centerd">
        <h1 className="hero-title  wow fadeInUp" data-wow-delay=".2s">
          Privacy Policy
        </h1>
        <nav aria-label="breadcrumb ">
          <ul className="breadcrumb wow fadeInUp" data-wow-delay=".6s">
            <li className="breadcrumb-item">
              <a className="breadcrumb-link" href="#0">
                <i className="bi bi-house icon " />
                home
              </a>
            </li>
            <li className="breadcrumb-item active">Privacy Policy</li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
  <section className="about mega-section" id="about">
    <div className="container">
      <div className="content-block" style={{ marginBottom: 0 }}>
        <div className="row">
          <div
            className="col-12 col-lg-12 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp "
            data-wow-delay="0.6s"
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2
                    style={{ fontSize: 45 }}
                    className="title wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <span className="hollow-text"> Privacy Policy</span>
                  </h2>
                </div>
              </div>
              <p className="about-text">
                This Privacy Policy governs the manner in which
                reptileindia.co.in collects, uses, maintains and discloses
                information collected from users (each, a "User") of the
                http://www.reptileindia.co.in website ("Site"). This privacy
                policy applies to the Site and offered by
                www.reptileindia.co.in, which is owned and operated by Carecone
                Technologies Private Limited.
              </p>
              <h6> 1. PERSONAL IDENTIFICATION INFORMATION</h6>
              <p className="about-text">
                {" "}
                We may collect personal identification information from Users in
                a variety of ways, including, but not limited to, when Users
                visit our site, register on the site, place an order, and in
                connection with other activities, services, features or
                resources we make available on our Site. Users may be asked for,
                as appropriate, name, email address, mailing address, phone
                number. Users may, however, visit our Site anonymously. We will
                collect personal identification information from Users only if
                they voluntarily submit such information to us. Users can always
                refuse to supply personally identification information, except
                that it may prevent them from engaging in certain Site related
                activities.{" "}
              </p>
              <p className="about-text">Name</p>
              <p className="about-text">E-mail.</p>
              <p className="about-text">
                User's GPS Location (Conditional) for attendance/tasks
              </p>
              <p className="about-text">
                Background Location (Conditional) for automated expense
              </p>
              <p className="about-text">
                Device Model and network connectivity.
              </p>
              <p className="about-text">
                Camera for selfie attendance and while filling the pictures
                related filed task forms
              </p>
              <p className="about-text">
                Audio for field data collection in audio field.
              </p>
              <p className="about-text" style={{ fontWeight: "bold" }}>
                <a href="https://policies.google.com/privacy" target="_blank">
                  Google Play Services
                </a>
              </p>
              <h6>2. NON-PERSONAL IDENTIFICATION INFORMATION</h6>
              <p className="about-text">
                {" "}
                We may collect non-personal identification information about
                Users whenever they interact with our Site. Non-personal
                identification information may include the browser name, the
                type of computer and technical information about Users means of
                connection to our Site, such as the operating system and the
                Internet service providers' utilized and other similar
                information.
              </p>
              <h6>3. WEB BROWSER COOKIES</h6>
              <p className="about-text">
                Currently we do not use cookies. Our Site may use "cookies" to
                enhance User experience. User's web browser places cookies on
                their hard drive for record-keeping purposes and sometimes to
                track information about them. User may choose to set their web
                browser to refuse cookies, or to alert you when cookies are
                being sent. If they do so, note that some parts of the Site may
                not function properly.
              </p>
              <h6>4. HOW WE USE COLLECTED INFORMATION</h6>
              <p className="about-text">
                Reptileindia.co.in may collect and use Users personal
                information for the following purposes:
              </p>
              <p className="about-text">
                • To improve customer service - Information you provide helps us
                respond to your customer service requests and support needs more
                efficiently.
              </p>
              <p className="about-text">
                • To personalize user experience - We may use information in the
                aggregate to understand how our Users as a group use the
                services and resources provided on our Site.
              </p>
              <p className="about-text">
                • To improve our Site - We may use feedback you provide to
                improve our products and services.
              </p>
              <p className="about-text">
                • To process payments - We may use the information Users provide
                about themselves when placing an order only to provide service
                to that order. We do not share this information with outside
                parties except to the extent necessary to provide the service.
              </p>
              <p className="about-text">
                • To run a promotion, contest, survey or other Site feature - To
                send Users information they agreed to receive about topics we
                think will be of interest to them.
              </p>
              <p className="about-text">
                • To send periodic emails - We may use the email address to send
                User information and updates pertaining to their order. It may
                also be used to respond to their inquiries, questions, and/or
                other requests. If User decides to opt-in to our mailing list,
                they will receive emails that may include company news, updates,
                related product or service information, etc. If at any time the
                User would like to unsubscribe from receiving future emails, we
                include detailed unsubscribe instructions at the bottom of each
                email or User may contact us via our Site.
              </p>
              <p className="about-text" style={{ fontWeight: "bold" }}>
                <a
                  href="https://www.whatsapp.com/legal/business-policy/"
                  target="_blank"
                >
                  Whatsapp Business Policies
                </a>
              </p>
              <h6>5. HOW WE PROTECT YOUR INFORMATION</h6>
              <p className="about-text">
                We adopt appropriate data collection, storage and processing
                practices and security measures to protect against unauthorized
                access, alteration, disclosure or destruction of your personal
                information, username, password, transaction information and
                data stored on our Site. For any such kind of queries you can
                also connect with us at info@reptileindia.co.in . Sensitive and
                private data exchange between the Site and its Users happens
                over a SSL secured communication channel and is encrypted and
                protected with digital signatures.
              </p>
              <h6>6. SHARING YOUR PERSONAL INFORMATION</h6>
              <p className="about-text">
                We may use third party service providers to help us operate our
                business and the Site or administer activities on our behalf,
                such as sending out newsletters or surveys. We may share your
                information with these third parties for those limited purposes
                provided that you have given us your permission.
              </p>
              <h6>7. GOOGLE ADSENSE</h6>
              <p className="about-text">
                Some of the ads may be served by Google. Google's use of the
                DART cookie enables it to serve ads to Users based on their
                visit to our Site and other sites on the Internet. DART uses
                "non personally identifiable information" and does NOT track
                personal information about you, such as your name, email
                address, physical address, etc. You may opt out of the use of
                the DART cookie by visiting the Google ad and content network
                privacy policy at http://www.google.com/privacy_ads.html{" "}
              </p>
              <h6>
                8. COMPLIANCE WITH CHILDREN'S ONLINE PRIVACY PROTECTION ACT
              </h6>
              <p className="about-text">
                Protecting the privacy of the very young is especially
                important. For that reason, we never collect or maintain
                information at our Site from those we actually know are under
                18, and no part of our website is structured to attract anyone
                under 18.
              </p>
              <h6>9. CHANGES TO THIS PRIVACY POLICY</h6>
              <p className="about-text">
                Reptileindia.co.in have the discretion to update this privacy
                policy at any time. When we do, we will send you an email. We
                encourage Users to frequently check this page for any changes to
                stay informed about how we are helping to protect the personal
                information we collect. You acknowledge and agree that it is
                your responsibility to review this privacy policy periodically
                and become aware of modifications.
              </p>
              <h6>10. CONFIDENTIALITY STATEMENT</h6>
              <p className="about-text">
                At Reptileindia.co.in, we place a major emphasis on maintaining
                confidentiality of Client/User information and Information
                Security Management. The following are key features of our
                Confidentiality Agreement, applicable to all our clients/users.
              </p>
              <p className="about-text">
                {" "}
                • We do not disclose any information pertaining to our Clients
                or to any third parties.
              </p>
              <p className="about-text">
                • We do not use our Clients identity or Clients brand for our
                marketing purposes, without their written consent.
              </p>
              <p className="about-text">
                • We never provide our Client's name or Client's information to
                other prospective clients for marketing purposes.
              </p>
              <p className="about-text">
                • We do not sell or disclose our Client list or Client
                Information to third parties.
              </p>
              <h6> 11. CONFIDENTIALITY AGREEMENT</h6>
              <p className="about-text">
                {" "}
                It is understood and agreed to that our Client may provide
                certain information that is and must be kept confidential. To
                ensure the protection of such information, and to preserve any
                confidentiality necessary under patent and/or trade secret laws,
                it is agreed that:{" "}
              </p>
              <p className="about-text">
                The Confidential Information to be disclosed can be described as
                and includes Invention description(s), technical and business
                information relating to proprietary ideas and inventions, ideas,
                patentable ideas, trade secrets, drawings and/or illustrations,
                patent searches, existing and/or contemplated products and
                services, research and development, production, costs, profit
                and margin information, finances and financial projections,
                customers, clients, marketing, and current or future business
                plans and models, regardless of whether such information is
                designated as "Confidential Information" at the time of its
                disclosure. Reptileindia.co.in shall limit disclosure of
                Confidential Information within its own organization to its
                directors, officers, partners, members, employees and/or
                independent contractors (collectively referred to as
                "affiliates") having a need to know. Reptileindia.co.in and
                affiliates will not disclose the confidential information
                obtained from the discloser unless required to do so by law.
              </p>
              <p className="about-text">
                This Agreement imposes no obligation upon Reptileindia.co.in
                with respect to any Confidential Information{" "}
              </p>
              <p className="about-text">
                (a) that was in Reptileindia.co.in possession before receipt
                from Client;{" "}
              </p>
              <p className="about-text">
                (b) is or becomes a matter of public knowledge through no fault
                of Reptileindia.co.in;{" "}
              </p>
              <p className="about-text">
                (c) is rightfully received by Reptileindia.co.in from a third
                party not owing a duty of confidentiality to the
                Reptileindia.co.in’s Client;
              </p>
              <p className="about-text">
                (d) is disclosed without a duty of confidentiality to a third
                party by, or with the authorization of Reptileindia.co.in;{" "}
              </p>
              <p className="about-text">
                (e) is public knowledge or the information is available in
                public domain; or{" "}
              </p>
              <p className="about-text">
                (f) is independently derived by Reptileindia.co.in.
              </p>
              This Agreement states the entire agreement between
              Reptileindia.co.in and its Client concerning the disclosure of
              Confidential Information. Any addition or modification to this
              Agreement must be made in writing and signed by the parties.
              <p />
              <p className="about-text">
                If any of the provisions of this Agreement are found to be
                unenforceable, the remainder shall be enforced as fully as
                possible and the unenforceable provision(s) shall be deemed
                modified to the limited extent required to permit enforcement of
                the Agreement as a whole. You are solely responsible any
                consequences, losses, or damages that we may directly or
                indirectly incur or suffer due to any unauthorized activities
                conducted by you, as explained above, and may incur criminal or
                civil liability.
              </p>
              <h6>12. CONTACT US</h6>
              <p className="about-text">
                For any query contact us at REPTILEINDIA.CO.IN
              </p>
              <p className="about-text">
                Carecone Technologies Private Limited{" "}
              </p>
              <p className="about-text">
                Feel free to write us at info@reptileindia.co.in ,we have a
                designated person to answer all your queries.
              </p>
              <div className="cta-area">
              <Link to="/contact-us">
                <a
                  className=" btn-solid reveal-start"
                 // href="https://www.reptileindia.co.in/contact-us"
                >
                  Contact Us
                </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</>

        <Footer />
        </>
    </Fragment>
  )
}
export default PrivacyPolicy;