import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer";
import { getBaseUrl } from "./../utils";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './aboutus.css';
import axios from "axios";
import SlickSlider from "./SlickSlider";

function Aboutus() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone_number, setphone_number] = useState("");
  const [msg_subject, setmsg_subject] = useState("");
  const [message, setmessage] = useState("");

  function handalSubmit() {
    if(!name){
      alert("Name field is required");
      return false;
    }
    if(!email){
      alert("Email field is required");
      return false;
    }
    if(!phone_number){
      alert("PhoneNumber field is required");
      return false;
    }
    if(!msg_subject){
      alert("Subject field is required");
      return false;
    }
    if(!message){
      alert("Message field is required");
      return false;
    }
    axios
      .post("https://www.reptileindia.co.in/laravel_website/api/website_send_email", {
        name: name,
        email:email,
        phone_number:phone_number,
        msg_subject:msg_subject,
        message:message
      })
      .then((res) => {
        console.log("res----------->",res);
        if (res.data.result){
          alert(res.data.message);
          setname("");
          setemail("");
          setphone_number("");
          setmsg_subject("");
          setmessage("");
          window.scrollTo(0, 0);
      }
      });
  }
  //window.scrollTo(0, 0);
  useEffect(() => {
    AOS.init({
      duration: 1200, // Customize the duration as needed
    });
  }, []);
  return (
    <Fragment>
      <>
        <>
          <Navbar />
          <>
          <section className="about">
          <section
    className="d-flex align-items-center page-hero  inner-page-hero "
    id="page-hero"
    style={{ backgroundImage: 'url("./assets/assets/images/About_us.jpg")' }}
  >
    <div
      className="overlay-photo-image-bg parallax"
      data-bg-img="https://www.reptileindia.co.in/laravel_website/public/assets/images/hero/inner-page-hero.jpg"
      data-bg-opacity={1}
    />
    <div className="overlay-color"/>
    <div className="container">
      <div className="hero-text-area centerd">
        <h1 className="hero-title  wow fadeInUp" data-wow-delay=".2s">
          About Us
        </h1>
        <nav aria-label="breadcrumb ">
          <ul className="breadcrumb wow fadeInUp" data-wow-delay=".6s">
            <li className="breadcrumb-item">
              <a className="breadcrumb-link" >
                <i className="bi bi-house icon " />
                home
              </a>
            </li>
            <li className="breadcrumb-item active">about us</li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
  <section className="about mega-section" id="about">
    <div className="container">
      <div className="content-block" style={{ marginBottom: 0 }}>
        <div className="row">
          <div
            className="col-12 col-lg-6 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp "
            data-wow-delay="0.6s"
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2
                    style={{ fontSize: 45 }}
                    className="title wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <span className="hollow-text">About Reptile</span>
                  </h2>
                </div>
              </div>
              <p className="about-text">
                Our Delhi{" "}
                <strong>Best App development Service in Avantika</strong> has
                been offering top-notch solutions to companies of all sorts for
                more than ten years. We take great satisfaction in offering
                creative and effective software solutions that assist businesses
                in streamlining their operations and accomplishing their
                objectives.
              </p>
              <p className="about-text">
                Our group of very talented designers, developers, and project
                managers collaborate to provide custom software solutions that
                are suited to the particular requirements of every customer.
                Since it's critical to remain ahead of the curve in a rapidly
                changing technological environment, we make it a point to
                continuously learn about and adjust to new technologies in order
                to deliver cutting-edge solutions.
              </p>
              <p className="about-text">
                Our primary focus is developing software that advances
                businesses by addressing real-world issues.{" "}
                <strong>Best App development Service in Avantika</strong>, Our
                reputation as a dependable partner for companies searching for
                dependable software solutions has been built on our dedication
                to quality and client satisfaction.
              </p>
              <p className="about-text">
                Join together with us to leverage our cutting-edge software
                development services to propel your company to new heights. Let
                us take care of the technical details so you can concentrate on
                operating your business, which is what you do best. Select our
                software provider for an easy and prosperous digital transition.
              </p>
              <div className="cta-area">
                <a className=" btn-solid reveal-start">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-6 d-flex align-items-center order-0 order-lg-1 about-col  wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="img-area  " data-tilt="">
              <div className="image   ">
                <img
                  className="about-img img-fluid "
                  loading="lazy"
                  src="https://www.reptileindia.co.in/laravel_website/public/assets/images/website/About_Us_1.png"
                  alt="Our vision"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-block">
        <div className="row">
          <div
            className="col-12 col-lg-6 d-flex align-items-center about-col  wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="img-area  ">
              <div className="image  " data-tilt="">
                <img
                  className="about-img img-fluid "
                  loading="lazy"
                  src="https://www.reptileindia.co.in/laravel_website/public/assets/images/website/About_Us_2.png"
                  alt="about"
                />
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-6 d-flex align-items-center about-col pad-start  wow fadeInUp "
            data-wow-delay="0.6s"
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2
                    style={{ fontSize: 40 }}
                    className=" title wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <span className="hollow-text"> Why Reptile</span>
                  </h2>
                </div>
              </div>
              <p className="about-text">
                <strong>Best ios App Development Service In Delhi</strong>, We
                are a group of creative, motivated people who are passionate
                about developing state-of-the-art software solutions at our
                core. Our organisation was established in Delhi with the goal of
                using technology to completely transform how businesses run. We
                are aware that having good software is essential for any
                business to succeed in the fast-paced world of today.
              </p>
              <p className="about-text">
                Our team, which is made up of extremely talented designers,
                developers, and project managers, collaborates to realise your
                ideas. ios App Development Service In Delhi To guarantee that
                our consumers get the highest outcomes possible, we employ the
                newest technology and techniques.
              </p>
              <p className="about-text">
                With our specialised software solutions, we have assisted
                countless businesses—from small startups to huge corporations—in
                achieving their objectives. We are committed to establishing
                enduring bonds with our clients and serving as a reliable
                resource for them as they pursue success.
              </p>
              <p className="about-text">
                Our organisation{" "}
                <strong> Best Web Development Service In Rohini</strong>,
                develops solutions that spur growth and have an impact rather
                than merely software. Become your technology partner with us to
                grow your company to new heights.
              </p>
              <div className="cta-area ">
                <a
                  className=" btn-solid "
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="about mega-section mb-5" id="about">
    <div className="container">
      <div className="content-block" style={{ marginBottom: 0 }}>
        <div className="row">
          <div className="col-12">
            <div className="contact-form-panel">
              <div className="sec-heading centered    ">
                <div className="content-area">
                  <h2 className=" title    wow fadeInUp" data-wow-delay=".4s">
                    Have any questions? Let's answer them
                  </h2>
                </div>
              </div>
              <div
                className="contact-form-inputs wow fadeInUp"
                data-wow-delay=".6s"
              >

                <div className="custom-form-area input-boxed">
                  {/*Form To have user messages*/}
                  <form
                        className="main-form"
                        id="contact-us-form"
                      >
                        <span className="done-msg" />
                        <div className="row ">
                          <div className="col-12 col-lg-6">
                            <div className="input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Name"
                                id="user-name"
                                name="name"
                                type="text"
                                value={name}
                                onChange={(e)=>{setname(e.target.value) }}
                              />
                              <label
                                className="input-label"
                                htmlFor="user-name"
                              >
                                {" "}
                                Name <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Email"
                                id="user-email"
                                name="email"
                                type="email"
                                value={email}
                                 onChange={(e)=>{setemail(e.target.value) }}
                              />
                              <label
                                className="input-label"
                                htmlFor="user-email"
                              >
                                {" "}
                                E-mail <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Phone"
                                id=""
                                name="phone_number"
                                type="text"
                                value={phone_number}
                                onChange={(e)=>{setphone_number(e.target.value) }}
                              />
                              <label
                                className="input-label"
                                htmlFor="user-email"
                              >
                                {" "}
                                Phone<span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 col-lg-6">
                            <div className="   input-wrapper">
                              <input
                                className="text-input"
                                placeholder="Enter Subject"
                                id="msg-subject"
                                name="msg_subject"
                                type="text"
                                value={msg_subject}
                                 onChange={(e)=>{setmsg_subject(e.target.value) }}
                              />
                              <label
                                className="input-label"
                                htmlFor="msg-subject"
                              >
                                {" "}
                                Subject <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 ">
                            <div className="   input-wrapper">
                              <textarea
                                className=" text-input"
                                placeholder="Enter Your Message"
                                id="msg-text"
                                name="message"
                                defaultValue={""}
                                value={message}
                                onChange={(e)=>{setmessage(e.target.value) }}
                              />
                              <label className="input-label" htmlFor="msg-text">
                                {" "}
                                your message <span className="req">*</span>
                              </label>
                              <span className="b-border" />
                              <i />
                              <span className="error-msg" />
                            </div>
                          </div>
                          <div className="col-12 submit-wrapper">
                            <button
                              className=" btn-solid"
                              id=""
                              type="button"
                              name="UserSubmit"
                              onClick={()=>{ handalSubmit() }}
                            >
                              Send your message
                            </button>
                          </div>
                        </div>
                      </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
          </section>
</>
        <Footer />
        </>

      </>

    </Fragment>
  )
}
export default Aboutus;