import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './aboutus.css';


function Portfolio() {
  const responsive = {
    0: { items: 4},
    568: { items: 6 },
    1025: { items:8 },
  };
  const items = [
    <div className="item">
      <img
        src="/assets/blogimage/Finance_Locker_Apps.jpg"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/Cloud-Service.jpg"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/Hisab_Book.png"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/Whatsapp-Business-API.jpg"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/SEO.jpg"
        alt="images"
        className="images_section"
      />
    </div>,
    <div className="item">
      <img
        src="/assets/blogimage/SMM.png"
        alt="images"
        className="images_section"
      />
    </div>,
  ];
  useEffect(() => {
     window.scrollTo(0, 0);
    AOS.init({
      duration: 1200,
    });
  }, []);
  return (
    <div>
     <Navbar />
        <div className="container">
          <div className="row main_images_section">
            <div className="col-lg-6 Contact_images_section">
              <img src="/assets/GetQueryImages/course_offer.png" alt="images" />
            </div>
            <div className="col-lg-6 text_contact_section">
              <h2>Our Portfolio</h2>
              <div
                style={{
                  background: "#DD5D7A",
                  width: "190px",
                  height: "4px",
                  float: "right",
                  paddingRight: "60px",
                }}
              ></div>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <div className="number_section" style={{gap:'50px'}}>
                <i class="fa fa-phone" aria-hidden="true"></i>{" "}
                <h6>+91 9311572747 </h6>
                <i class="fa fa-phone" aria-hidden="true"></i>{" "}
                <h6>+91 8178334747 </h6>
              </div>
              <div className="fa_fa_section">
                <i class="fa fa-instagram" aria-hidden="true"></i>
                <i class="fa fa-whatsapp" aria-hidden="true"></i>
                <i class="fa fa-linkedin" aria-hidden="true"></i>
                <i class="fa fa-skype" aria-hidden="true"></i>
              </div>
              <div>
                <button>Get Query</button>
                <button>Contact</button>
              </div>
              <div className="Routing_port_folio_section"></div>
            </div>
          </div>
      </div>
      <div className="container client_portfolio_section">
        <div className="row">
          <div className="col-4">
            <h2>Our Clients</h2>
          </div>
          <div className="col-8">
          <AliceCarousel
                autoPlay
                mouseTracking
                items={items}
                responsive={responsive}
                controlsStrategy="alternate"
                animationDuration={1000}
                animationType="fadeout"
                infinite
                touchTracking={false}
                disableDotsControls
                disableButtonsControls
              />
          </div>
        </div>
      </div>
     <Footer />
    </div>
  )
}
export default Portfolio;