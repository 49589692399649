import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CardSlider.css'; // Custom CSS for styling

const CardSlider = () => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight
      });
    
      useEffect(() => {
        const handleResize = () => {
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight
          });
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    // Sample data for cards
    //   const cards = [
    //     { id: 1, title: 'Card 1', description: 'Description 1', image: 'https://via.placeholder.com/150' },
    //     { id: 2, title: 'Card 2', description: 'Description 2', image: 'https://via.placeholder.com/150' },
    //     { id: 3, title: 'Card 3', description: 'Description 3', image: 'https://via.placeholder.com/150' },
    //     // Add more cards as needed
    //   ];
    const cards = [
        { id: 1, image: "assets/HomeIimages/working_images/gaming.webp", alt: "Healthcare", title: "Healthcare", description: "Providing software solutions for healthcare providers, hospitals, clinics, and healthcare organisations to streamline operations, improve patient care, and enhance efficiency." },
        { id: 2, image: "assets/HomeIimages/working_images/logistics-distribution.webp", alt: "Second item", title: "Logistics and Transportation", description: "Offering technology solutions for logistics companies, transportation providers, and supply chain management firms to optimise routes, track shipments, and improve overall operational efficiency." },
        { id: 3, image: "assets/HomeIimages/working_images/retail-ecommerce.webp", alt: "Third item", title: "Retail and Ecommerce", description: "Developing e-commerce platforms, inventory management systems, and customer relationship management (CRM) solutions for retail businesses to enhance online sales and customer experience." },
        { id: 4, image: "assets/HomeIimages/working_images/education-e-learning.webp", alt: "Fourth item", title: "Education", description: "Offering e-learning platforms, student management systems, and educational apps for schools, universities, and educational institutions to facilitate remote learning and improve student engagement." },
        { id: 5, image: "assets/HomeIimages/working_images/real-estate.webp", alt: "Fifth item", title: "Real Estate", description: "Developing property management software, CRM systems, and real estate websites for real estate agencies, property developers, and real estate professionals to streamline operations and enhance customer service." },
        // { id: 6,image: "assets/HomeIimages/working_images/logistics-distribution.webp", alt: "Sixth item", title: "Logistics and Transportation", description: "Offering technology solutions for logistics companies, transportation providers, and supply chain management firms to optimise routes, track shipments, and improve overall operational efficiency." },
    ];

    // Settings for the Slider
    var width = windowSize ? windowSize.width : "";
    let slidesToShow = 5;
    if(width){
        if(width < 600){
            slidesToShow = 1;
        }
        if(width < 800 && width > 600){
            slidesToShow = 2;
        }
    }
    console.log("slidesToShow---------->",slidesToShow);
    console.log("windowSize---------->",width);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '50px',
    };

    return (
        <div className="card-slider-container">
            <Slider {...settings}>
                {cards.map(card => (
                    <div key={card.id} className="card-item">
                        <div className="card-content">
                            <img src={card.image} alt={card.title} />
                            <div className="overlay">
                                <h3>{card.title}</h3>
                                <div
                                    style={{
                                        background: "white",
                                        height: 3,
                                        width: 120,
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                />
                                <p>{card.description}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default CardSlider;
