import React, { Fragment, useEffect, useRef, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';


function SwiperSlider() {
    return (
        <Carousel data-bs-theme="dark">
            <Carousel.Item>
            <div className="porducts_images_mobile">
        <img src="assets/HomeIimages/working_images/Gowisekart@0.5x.png" alt="" />
        <div className="text_porducts_section_four">
          <h1>Gowisekart</h1>
          <p>
            Their app and website, which we built, provide users with a seamless
            shopping experience, making it easy to browse, order, and track
            their&nbsp;purchases.
          </p>
        </div>
      </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className="porducts_images_mobile">
        <img src="assets/HomeIimages/working_images/Security@0.5x.png" alt="" />
        <div className="text_porducts_section_five">
          <h1>Reptile EMI Security</h1>
          <p style={{ fontSize: "13.4px" }}>
            Reptile EMI Security is a feature that ensures all financial
            transactions are secure and encrypted. This safeguard protects
            sensitive information from unauthorized access and keeps business
            data safe from potential threats
          </p>
        </div>
      </div>
            </Carousel.Item>
            <Carousel.Item>
            <div className="porducts_images_mobile">
        <img src="assets/HomeIimages/working_images/Tracko@0.33x.png" alt="" />
        <div className="text_porducts_section_six">
          <h1>Reptile Tracko</h1>
          <p>
            Reptile tracko is a time tracking and scheduling app that allows
            employees to clock in and out, track their location, and log their
            hours worked. It also offers GPS tracking for remote employees
          </p>
        </div>
      </div>
            </Carousel.Item>
            
        </Carousel>
    );
};
export default SwiperSlider;