import { Carousel, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

function CarouselPage() {

  const size = useWindowSize();
  const isSmallScreen = size.width < 768;

  const items = [
    { src: "assets/HomeIimages/working_images/gaming.webp", alt: "Healthcare", title: "Healthcare", text: "Providing software solutions for healthcare providers, hospitals, clinics, and healthcare organisations to streamline operations, improve patient care, and enhance efficiency." },
    { src: "assets/HomeIimages/working_images/logistics-distribution.webp", alt: "Second item", title: "Logistics and Transportation", text: "Offering technology solutions for logistics companies, transportation providers, and supply chain management firms to optimise routes, track shipments, and improve overall operational efficiency." },
    { src: "assets/HomeIimages/working_images/retail-ecommerce.webp", alt: "Third item", title: "Retail and Ecommerce", text: "Developing e-commerce platforms, inventory management systems, and customer relationship management (CRM) solutions for retail businesses to enhance online sales and customer experience." },
    { src: "assets/HomeIimages/working_images/education-e-learning.webp", alt: "Fourth item", title: "Education", text: "Offering e-learning platforms, student management systems, and educational apps for schools, universities, and educational institutions to facilitate remote learning and improve student engagement." },
    { src: "assets/HomeIimages/working_images/real-estate.webp", alt: "Fifth item", title: "Real Estate", text: "Developing property management software, CRM systems, and real estate websites for real estate agencies, property developers, and real estate professionals to streamline operations and enhance customer service." },
    { src: "assets/HomeIimages/working_images/logistics-distribution.webp", alt: "Sixth item", title: "Logistics and Transportation", text: "Offering technology solutions for logistics companies, transportation providers, and supply chain management firms to optimise routes, track shipments, and improve overall operational efficiency." },
  ];

  const groupedItems = isSmallScreen
    ? items.map((item, index) => [item])
    : items.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / 6);
      if (!result[chunkIndex]) {
        result[chunkIndex] = [];
      }
      result[chunkIndex].push(item);
      return result;
    }, []);

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Carousel>
        {groupedItems.map((group, idx) => (
          <Carousel.Item key={idx}>
            <Row>
              {group.map((item, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={2}>
                  <img
                    className="d-block w-100"
                    src={item.src}
                    alt={item.alt}
                  />
                  {/* <h3 style={{ position: "absolute", top: 78, left: 223 }}>
                    {item.title}
                  </h3> */}
                  <div className="text_services">
                    <h4>
                    {item.title}
                    </h4>
                    <div />
                    <p>
                      Offering technology solutions for logistics companies, transportation
                      providers, and supply chain management firms to optimise routes, track
                      shipments, and improve overall operational efficiency.
                    </p>
                  </div>
                </Col>
              ))}
              {/* <Col key={0} xs={12} sm={6} md={4} lg={2}>
                  <img
                    className="d-block w-100"
                    src={items[0].src}
                    alt={items[0].alt}
                  />
                  <h3 style={{ position: "absolute", top: 78, left: 36 }}>
                    {items[0].title}
                  </h3>
                </Col>
                <Col key={1} xs={12} sm={6} md={4} lg={2}>
                  <img
                    className="d-block w-100"
                    src={items[1].src}
                    alt={items[1].alt}
                  />
                  <h3 style={{ position: "absolute", top: 78, left: 200 }}>
                    {items[1].title}
                  </h3>
                </Col>
                <Col key={2} xs={12} sm={6} md={4} lg={2}>
                  <img
                    className="d-block w-100"
                    src={items[2].src}
                    alt={items[2].alt}
                  />
                  <h3 style={{ position: "absolute", top: 78, left: 500 }}>
                    {items[2].title}
                  </h3>
                </Col>
                <Col key={3} xs={12} sm={6} md={4} lg={2}>
                  <img
                    className="d-block w-100"
                    src={items[3].src}
                    alt={items[3].alt}
                  />
                  <h3 style={{ position: "absolute", top: 78, left: 500 }}>
                    {items[3].title}
                  </h3>
                </Col>
                <Col key={4} xs={12} sm={6} md={4} lg={2}>
                  <img
                    className="d-block w-100"
                    src={items[4].src}
                    alt={items[4].alt}
                  />
                  <h3 style={{ position: "absolute", top: 78, left: 500 }}>
                    {items[4].title}
                  </h3>
                </Col>
                <Col key={5} xs={12} sm={6} md={4} lg={2}>
                  <img
                    className="d-block w-100"
                    src={items[5].src}
                    alt={items[5].alt}
                  />
                  <h3 style={{ position: "absolute", top: 78, left: 500 }}>
                    {items[5].title}
                  </h3>
                </Col> */}
            </Row>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  )
}

export default CarouselPage;
