import React, { Fragment } from "react";
import { Link } from "react-router-dom";
function Footer(){
    return(
<Fragment>
    <>
    <section className={5}>
            <div className="contact_container">
              <div className="container contact_section">
                <div className="row">
                  <div className="col-lg-4 last_contact">
                    <div className="image">
                      <img src="/assets/HomeIimages/Reptile_R_Logo/phone.gif" />
                    </div>
                    <div className="text_sec">
                      <h5 style={{ color: "white" }}>(+91)9311572747&nbsp;</h5>
                      <h5 style={{ color: "white" }}>(+91)8178334747&nbsp;</h5>
                    </div>
                  </div>
                  <div className="col-lg-4 last_contact_image_sec">
                    <center>
                      <div className="image_sec">
                        <img src="/assets/HomeIimages/Reptile_R_Logo/Frame.png" />
                      </div>
                    </center>
                  </div>
                  <div className="col-lg-4 last_contact">
                    <div className="text_sec">
                      <h5 style={{ textAlign: "right", color: "white" }}>
                        nidhi@reptileindia.co.in
                      </h5>
                      <h5 style={{ textAlign: "right", color: "white" }}>
                        sales@reptileindia.co.in
                      </h5>
                    </div>
                    <div className="image">
                      <img src="/assets/HomeIimages/Reptile_R_Logo/email_17.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* end contact section */}
          {/* start footer codding */}
          {/* <footer>
            <div className="footer_section">
              <div className="container-fluid footer_container">
                <div className="row">
                  <div className="col-md-3 about_section">
                    <div className="heading">
                      <h4>About Us</h4>
                    </div>
                    <p>
                      We have 3+ years experience. Helping you overcome technology
                      challenges.
                    </p>
                    <div>
                      <a href="https://www.facebook.com/reptileitservice/">
                        {" "}
                        <i className="fa fa-facebook-square" aria-hidden="true" />
                      </a>
                      <a href="https://www.instagram.com/reptileitservice/">
                        {" "}
                        <i className="fa fa-instagram" aria-hidden="true" />
                      </a>
                      <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Freptileindia">
                        {" "}
                        <i className="fa fa-twitter" aria-hidden="true" />
                      </a>
                      <a href="https://api.whatsapp.com/send?phone=+919311572747&text=Hello">
                        <i className="fa fa-whatsapp" aria-hidden="true" />
                      </a>
                      <a href="https://www.linkedin.com/company/reptileitservice/">
                        <i className="fa fa-linkedin" aria-hidden="true" />
                      </a>
                     
                    </div>
                    <p>© 2024 Created by: Carecone Technologies Pvt Ltd</p>
                  </div>
                  <div className="col-md-3 our_porduct">
                    <div className="heading">
                      <h4>Our Porduct</h4>
                    </div>
                    <div className="icon">
                      <span>Mobile Security</span>
                    </div>
                    <div className="icon">
                      <span>Reptile Bahikhata</span>
                    </div>
                    <div className="icon">
                      <span>Reptile Tracko</span>
                    </div>
                    <div className="icon">
                      <span>EMI Security Plus</span>
                    </div>
                    <div className="icon">
                      <span>EMI Security</span>
                    </div>
                    <div className="icon">
                      <span>Reptile Tracko</span>
                    </div>
                  </div>
                  <div className="col-md-3 Industries">
                    <div className="heading">
                      <h4>Our Services</h4>
                    </div>
                    <div className="icon">
                      <span>Web Development</span>
                    </div>
                    <div className="icon">
                      <span>Mobile App Development</span>
                    </div>
                    <div className="icon">
                      <span>Ecommerce Development</span>
                    </div>
                    <div className="icon">
                      <span>Digital Marketing</span>
                    </div>
                    <div className="icon">
                      <span>Branding Services</span>
                    </div>
                  </div>
                  <div className="col-md-3 about_section">
                    <div className="heading">
                      <h4>Contact Us</h4>
                    </div>
                    <p style={{ paddingBottom: 0 }}>
                      Carecone Technologies private limited 265, 2nd floor, Aggarwal
                      City Plaza, Plot No.-17, Mangalam Place, Sector-3 Rohini, New
                      Delhi-110085
                    </p>
                    <div className="addres_section">
                      <div className="Call_sec">
                        <h6>Phone:</h6>
                        <h6>+91 8178334747 ,9311572747</h6>
                      </div>
                      <div className="Email_footer">
                        <h6>Email:</h6>
                        <h6>sales@reptileindia.co.in</h6>
                      </div>
                      <div className="newletter">
                        <h6>Newsletter:</h6>
                        <form>
                          <div>
                            <input type="email" placeholder="Enter Your Email" />
                            <button className="new_button">
                              <i className="fa fa-arrow-right" aria-hidden="true" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer> */}
          <>
  {/* start footer codding */}
  <footer>
    <div className="footer_section">
      <div className="container-fluid footer_container">
        <div className="row">
          <div className="col-md-3 about_section">
            <div className="heading">
              <h4>About Us</h4>
            </div>
            <p>
              We have 3+ years experience. Helping you overcome technology
              challenges.
            </p>
            <div>
              <a href="https://www.facebook.com/reptileitservice/">
                {" "}
                <i className="fa fa-facebook-square" aria-hidden="true" />
              </a>
              <a href="https://www.instagram.com/reptileitservice/">
                {" "}
                <i className="fa fa-instagram" aria-hidden="true" />
              </a>
              <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Freptileindia">
                {" "}
                <i className="fa fa-twitter" aria-hidden="true" />
              </a>
              <a href="https://api.whatsapp.com/send?phone=+919311572747&text=Hello">
                <i className="fa fa-whatsapp" aria-hidden="true" />
              </a>
              <a href="https://www.linkedin.com/company/reptileitservice/">
                <i className="fa fa-linkedin" aria-hidden="true" />
              </a>
              {/* <img src="HomeIimages/Reptile R Logo/Reptile Logo-01.png"> */}
            </div>
            <p>© 2020 Created by: Carecone Technologies Pvt Ltd</p>
          </div>
          <div className="col-md-2 our_porduct">
            <div className="heading">
              <h4>Our Porduct</h4>
            </div>
            <div className="icon">
              <span>Mobile Security</span>
            </div>
            <div className="icon">
              <span>Reptile Bahikhata</span>
            </div>
            <div className="icon">
              <span>Reptile Tracko</span>
            </div>
            <div className="icon">
              <span>EMI Security Plus</span>
            </div>
            <div className="icon">
              <span>EMI Security</span>
            </div>
            <div className="icon">
              <span>Reptile Tracko</span>
            </div>
          </div>
          <div className="col-md-2 Industries">
            <div className="heading">
              <h4>Our Services</h4>
            </div>
            <div className="icon">
              <span>Web Development</span>
            </div>
            <div className="icon">
              <span>Mobile App Development</span>
            </div>
            <div className="icon">
              <span>Ecommerce Development</span>
            </div>
            <div className="icon">
              <span>Digital Marketing</span>
            </div>
            <div className="icon">
              <span> Branding Services</span>
            </div>
          </div>
          <div className="col-md-2 our_porduct">
            <div className="heading">
              <h4>Quick Links</h4>
            </div>
            <Link to="/">
            <div className="icon">
              <span>Home</span>
            </div>
            </Link>
            <Link to="/contact-us">
            <div className="icon">
              <span>Contact Us</span>
            </div>
            </Link>
            <Link to="/about-us">
            <div className="icon">
              <span>About Us</span>
            </div>
            </Link>
            <Link to="/privacy-policy">
            <div className="icon">
              <span>Privacy Policy</span>
            </div>
            </Link>
            <Link to="/terms-condition">
            <div className="icon">
              <span>Term &amp; Condition</span>
            </div>
          </Link>
            <Link to="/refund-policy">
            <div className="icon">
              <span>Refund Policy</span>
            </div>
          </Link>
          </div>
          <div className="col-md-3 about_section">
            <div className="heading">
              <h4>Contact Us</h4>
            </div>
            <p style={{ paddingBottom: 0 }}>
              Carecone Technologies private limited 265, 2nd floor, Aggarwal
              City Plaza, Plot No.-17, Mangalam Place, Sector-3 Rohini, New
              Delhi-110085
            </p>
            <div className="addres_section">
              <div className="Call_sec">
                <h6>Phone: +91 8178334747 ,9311572747</h6>
              </div>
              <div className="Email_footer">
                <div>
                <h6>
                  Email : sales@reptileindia.co.in
                </h6>
                </div>
              </div>
              <div className="newletter">
                <h6>Newsletter:</h6>
                <form>
                  <div>
                    <input type="email" placeholder="Enter Your Email" />
                    <Link to="/contact-us">
                    <button className="new_button">
                      <i className="fa fa-arrow-right" aria-hidden="true" />
                    </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</>


          
          {/* <section>
            <div className="btm_whtsapp" id="whtsaopn">
              <div className="btm_whtinr">
                <a
                  href="https://api.whatsapp.com/send?phone=+919311572747&text=Hello"
                  className="whtsap_clck"
                  target="_blank"
                  id="stye-5"
                >
                  <img src="/assets/HomeIimages/certificate/wp.gif" />
                </a>
              </div>
            </div>
            <div className="massage" id="whtsaopn">
              <div className="btm_whtinr_massage">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbOgYC_R7QESK0H174Uk4Dfb44VG2XupzpxA&s"
                  alt=""
                  onclick="message()"
                />
              </div>
            </div>
            <div className="chat_box_section" id="chat_section">
              <div className="header_top">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSbOgYC_R7QESK0H174Uk4Dfb44VG2XupzpxA&s"
                  alt=""
                />
                <h5>Expertise</h5>
                <i className="fa fa-window-minimize" onclick="hide_message()" />
              </div>
              <div className="chat_section">
                <div className="text_scroll">
                  <p>
                    Hi 👋 How Can We Help You Today? Would you like to see a free demo
                    session of our app, its features, and cost estimation?
                  </p>
                  <h6>
                    The chat has been{" "}
                    <span style={{ color: "red" }}>closed due to long user</span>{" "}
                    inactivity.
                  </h6>
                  <hr style={{ color: "white", height: 1 }} />
                  <p>
                    Hi 👋 How Can We Help You Today? Would you like to see a free demo
                    session of our app, its features, and cost estimation?
                  </p>
                  <p>
                    Hi 👋 How Can We Help You Today? Would you like to see a free demo
                    session of our app, its features, and cost estimation?
                  </p>
                </div>
              </div>
              <div className="text_massages">
                <form action="">
                  <input
                    type="text"
                    name="massage"
                    className="text_massage"
                    placeholder="Write a message....."
                  />
                </form>
                <i className="fa fa-paper-plane-o" aria-hidden="true" />
              </div>
              <div className="power_by_chat">
                <h6>
                  Powered by{" "}
                  <span style={{ fontSize: 15, color: "red" }}>Live Chat</span>
                </h6>
              </div>
            </div>
            <div id="call_popup_mn" className="side_pp side_call_pp" onclick="call()">
              <div className="sidepp_popup_sml cll_pop">
                <span>Get a Call Back</span>
              </div>
            </div>
            <div className="call_back_section" id="call_section">
              <i className="fa fa-times" aria-hidden="true" onclick="hide()" />
              <h2>
                Discuss your Idea with a{" "}
                <span style={{ color: "orangered", fontWeight: 700 }}>CTO!</span>
              </h2>
              <form>
                <center>
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    className="Name_input"
                  />
                  <div className="countery_select">
                    <select name="" id="">
                      <option value="">+91</option>
                    </select>
                    <input
                      type="number"
                      name="number"
                      placeholder="Mobile Number"
                      className="email"
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    placeholder="BUsiness Email"
                    className="Name_input"
                  />
                </center>
                <button type="submit" className="button_submit">
                  Call Me Now
                </button>
              </form>
            </div>
          </section> */}
  
    </>
</Fragment>
    )
}
export default Footer;