import Navbar from "../Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Footer from "../../Components/footer";
import "./BlogHome.css";
import { useLocation,useNavigate } from "react-router-dom";
import { useEffect } from "react";
const Mobile = () => {
  const responsive = {
    0: { items: 1 },
    568: { items: 1 },
    1025: { items: 2 },
  };
  const items = [
    <div className="item">
      <div className="images_section_blog_section">
        <img
          src="/assets/blogimage/Mobile-App-Development.jpg"
          alt="images"
          className="images_section"
        />
      </div>
      <div className="text_slide_section_blog">
        <h5>Mobile App Development</h5>
        <h4>Welcome to our latest blog post on Mobile App Development!</h4>
        <p>In today fast-paced digital landscape, mobile apps have become an essential tool for businesses looking.....Read More</p>
        <div className="date_section">
        <p style={{color:"orangered"}}><i class="fa fa-calendar" aria-hidden="true"></i> 29 june 2024</p>
        <p style={{color:"orangered"}}> <i class="fa fa-commenting" aria-hidden="true"></i> 71</p>
        </div>
      </div>
    </div>,
    <div className="item">
      <div className="images_section_blog_section">
        <img
          src="/assets/blogimage/Cloud-Service.jpg"
          alt="images"
          className="images_section"
        />
      </div>
      <div className="text_slide_section_blog">
        <h5>Cloud Services</h5>
        <h4>Welcome to our latest blog post on Cloud Services</h4>
        <p>Transforming the Future of Business and Technology
        Few technological advancements</p>
        <div className="date_section">
        <p style={{color:"orangered"}}><i class="fa fa-calendar" aria-hidden="true"></i> 29 june 2024</p>
        <p style={{color:"orangered"}}> <i class="fa fa-commenting" aria-hidden="true"></i> 71</p>
        </div>
      </div>
    </div>,
    <div className="item">
      <div className="images_section_blog_section">
        <img
          src="/assets/blogimage/Finance_Locker_Apps.jpg"
          alt="images"
          className="images_section"
        />
      </div>
      <div className="text_slide_section_blog">
        <h5>Finance Locker Apps</h5>
        <h4>Welcome to our latest blog post on Finance Locker Apps</h4>
        <p>Finance Locker, the place to go if you want to become an expert in personal finance and open the doors to a safe and wealthy future</p>
        <div className="date_section">
        <p style={{color:"orangered"}}><i class="fa fa-calendar" aria-hidden="true"></i> 29 june 2024</p>
        <p style={{color:"orangered"}}> <i class="fa fa-commenting" aria-hidden="true"></i> 71</p>
        </div>
      </div>
    </div>,
    <div className="item">
      <div className="images_section_blog_section">
        <img
          src="/assets/blogimage/Tracking-Application.jpg"
          alt="images"
          className="images_section"
        />
      </div>
      <div className="text_slide_section_blog">
        <h5>Tracking Application</h5>
        <h4>Welcome to our latest blog post on Tracking Application</h4>
        <p>Reptile Tracko is a comprehensive mobile application that combines location tracking, attendance management, task management, and customer management</p>
        <div className="date_section">
        <p style={{color:"orangered"}}><i class="fa fa-calendar" aria-hidden="true"></i> 29 june 2024</p>
        <p style={{color:"orangered"}}> <i class="fa fa-commenting" aria-hidden="true"></i> 71</p>
        </div>
      </div>
    </div>,
    <div className="item">
      <div className="images_section_blog_section">
        <img
          src="/assets/blogimage/Web_Dev.png"
          alt="images"
          className="images_section"
        />
      </div>
      <div className="text_slide_section_blog">
        <h5>Web development</h5>
        <h4>Welcome to our latest blog post on Web development</h4>
        <p>User Interface (UI) and User Experience (UX) design play a critical role in developing interesting, user-friendly websites and applications in the quickly changing digital ecosystem</p>
        <div className="date_section">
          <p style={{color:"orangered"}}><i class="fa fa-calendar" aria-hidden="true"></i> 29 june 2024</p>
          <p style={{color:"orangered"}}> <i class="fa fa-commenting" aria-hidden="true"></i> 71</p>
        </div>
      </div>
    </div>,
    <div className="item">
      <div className="images_section_blog_section">
        <img
          src="/assets/blogimage/Whatsapp-Business-API.jpg"
          alt="images"
          className="images_section"
        />
      </div>
      <div className="text_slide_section_blog">
        <h5>Whatsapp Business API</h5>
        <h4>Welcome to our latest blog post on Whatsapp Business API</h4>
        <p>Are you looking to take your business communication to the next level? Look no further than the power of WhatsAp</p>
        <div className="date_section">
        <p style={{color:"orangered"}}><i class="fa fa-calendar" aria-hidden="true"></i> 29 june 2024</p>
        <p style={{color:"orangered"}}> <i class="fa fa-commenting" aria-hidden="true"></i> 71</p>
        </div>
      </div>
    </div>,
  ];
  const location = useLocation();
  const navigate = useNavigate();
  if(!location){
    navigate('/');
  };
  const state = location.state;
  const {BlogData} = state
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className="container top_header_social_media">
        <div className="row">
          <div className="col-12 slide">
            <AliceCarousel
              autoPlay
              mouseTracking
              items={items}
              responsive={responsive}
              controlsStrategy="alternate"
              animationDuration={5000}
              animationType="fadeout"
              infinite
              touchTracking={false}
              disableDotsControls
              disableButtonsControls
            />
          </div>
          
        </div>
      </div>
      <div className="container Social_Media_marketing_section">
        <div className="row">
        <div className="Nav_section">
            <h4>Latest Blogs :{BlogData.Date}</h4>
            <div>
              <form>
                <input type="text" name="text" placeholder="Search Blog" />
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
          <div className="col-8 images_section_blog_1">        
          <div className="images_social_media">
              <div className="social_images_text">
                <div>
                  <h6>{BlogData.Heading}</h6>
                <h2>{BlogData.Description1}</h2>
                </div>
                <img src={BlogData.images} alt="images" />
              </div>
              <h1>{BlogData.Heading}</h1>
              <p>{BlogData.ContentAll}</p>
              <p style={{paddingTop:'20px'}}>{BlogData.FinanceQustionsss}</p>
              <div className="Qustio_section">
              <i class="fa fa-hand-o-right" aria-hidden="true"></i> <h5>{BlogData.FinanceQustion}</h5>
              </div>
              <div dangerouslySetInnerHTML={{ __html: BlogData.FinanceAnswer }} />
              <p style={{paddingTop:'15px'}}>{BlogData.pOne}</p>
              <div dangerouslySetInnerHTML={{ __html: BlogData.pTow }} />
              <p style={{paddingTop:'15px'}}>{BlogData.pThree}</p>
              <div dangerouslySetInnerHTML={{ __html: BlogData.pFour }} />
              <p style={{paddingTop:'15px'}}>{BlogData.pFive}</p>
              <div className="Qustio_section">
              <i class="fa fa-hand-o-right" aria-hidden="true"></i> <h5>{BlogData.pSix}</h5>
              </div>
              
                  <p style={{color:"orangered"}}>{BlogData.ulsection}</p>
                  <div dangerouslySetInnerHTML={{ __html: BlogData.liAnswer }} />
                  <p>{BlogData.lianswertow}</p>
                  <p>{BlogData.lianswerthree}</p>
                  <p>{BlogData.lianswerfoue}</p>
                  <p>{BlogData.piAnswer}</p>
              
              <div className="Qustio_section">
              <i class="fa fa-hand-o-right" aria-hidden="true"></i> <h5>{BlogData.pSeven}</h5>
              </div>
              <div dangerouslySetInnerHTML={{ __html: BlogData.PsevenAnswer }} />
              <p>{BlogData.pTow1}</p>
              <p>{BlogData.pTow2}</p>
              <p>{BlogData.Ptowe}</p>
              <p>{BlogData.PTow3}</p>
            </div>
            {/* <div className="images_social_media">
              <div className="social_images_text">
                <div>
                  <h6>2. Prioritize Authenticity</h6>
                <h2>2. Prioritize Authenticity</h2>
                </div>
                <img src="/assets/blogimage/0e4a69_13725893db864c3c89f0b9da7a16f21a~mv2.webp" alt="images" />
              
              </div>
            <p>Be Genuine</p>
            <ul>
              <li>Share your true self, values, and experiences to connect with your audience on a deeper level.</li>
            </ul>
            <p>Transparency</p>
            <ul>
              <li>Be honest about your processes, challenges, and successes. This builds trust and loyalty.</li>
            </ul>
            <p>User-Generated Content</p>
            <ul>
              <li>Encourage your audience to share their content and feature it on your profiles, showcasing real-life interactions with your brand.</li>
            </ul>
            </div> */}

            {/* <div className="images_social_media">
              <div className="social_images_text">
                <div>
                  <h6>3. Collaborate with Influencers</h6>
                <h2>3. Collaborate with Influencers.</h2>
                </div>
                
              <img src="/assets/blogimage/ia-banner-img_en.avif" alt="images" />
              </div>
             <p>Identify Relevant Influencers</p>
             <ul>
              <li> Partner with influencers who align with your brand values and have a genuine connection with their audience.</li>
             </ul>
             <p>Collaborative Content</p>
             <ul>
              <li>Create content together, such as joint videos, shout-outs, or co-hosted live sessions.</li>
             </ul>
             <p>Influencer Takeovers</p>
             <ul>
              <li>Allow influencers to take over your account for a day to provide fresh content and attract new followers.</li>
             </ul>
            </div> */}
            
          </div>
          <div className="col-4 left_blog_section_text">
            <div className="text_section">
              <div>
                <img src="/assets/blogimage/Whatsapp-Business-API.jpg" />
                <div className="left_text_section_item">
                  <h5>Latest Post</h5>
                  <h6>3/7/2024</h6>
                  </div>
                  <h6>WhatsApp Business Api</h6>
                  <p>Are you looking to take your business communication to the next level? Look no further than the power of WhatsApp...... </p>
              </div>
            </div>
            <div className="text_section">
              <div>
                <img src="/assets/blogimage/Cloud-Service.jpg" />
                <div className="left_text_section_item">
                  <h5>Latest Post</h5>
                  <h6>3/7/2024</h6>
                  </div>
                  <h6>Cloud Services</h6>
                  <p> Transforming the Future of Business and Technology
                  Few technological advancements have had as much of an influence as cloud computing in the ever changing field of technology....</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Mobile;
