import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Footer from "../Components/footer";
import { getBaseUrl } from "./../utils";
import AOS from 'aos';
import 'aos/dist/aos.css';
import './aboutus.css';


function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init({
      duration: 1200, // Customize the duration as needed
    });
  }, []);
  return (
    <Fragment>
        <>
          <Navbar />
  <section
    className="d-flex align-items-center page-hero  inner-page-hero "
    id="page-hero"
    style={{ backgroundImage: 'url("assets/assets/images/solutionbanner.png")' }}
  >
    <div
      className="overlay-photo-image-bg parallax"
      data-bg-img="https://www.reptileindia.co.in/laravel_website/public/assets/images/hero/inner-page-hero.jpg"
      data-bg-opacity={1}
    />
    <div className="overlay-color" data-bg-opacity=".75" />
    <div className="container">
      <div className="hero-text-area centerd">
        <h1 className="hero-title  wow fadeInUp" data-wow-delay=".2s">
          Refund Policy
        </h1>
        <nav aria-label="breadcrumb ">
          <ul className="breadcrumb wow fadeInUp" data-wow-delay=".6s">
            <li className="breadcrumb-item">
              <a className="breadcrumb-link" href="#0">
                <i className="bi bi-house icon " />
                home
              </a>
            </li>
            <li className="breadcrumb-item active">Refund Policy</li>
          </ul>
        </nav>
      </div>
    </div>
  </section>
  <section className="about mega-section" id="about">
    <div className="container">
      <div className="content-block" style={{ marginBottom: 0 }}>
        <div className="row">
          <div
            className="col-12 col-lg-12 d-flex align-items-center order-1 order-lg-0 about-col pad-end  wow fadeInUp "
            data-wow-delay="0.6s"
          >
            <div className="text-area ">
              <div className="sec-heading  light-title ">
                <div className="content-area">
                  <h2
                    style={{ fontSize: 45 }}
                    className="title wow fadeInUp"
                    data-wow-delay=".4s"
                  >
                    <span className="hollow-text"> Refund Policy</span>
                  </h2>
                </div>
              </div>
              <p className="about-text">
                This Refund Policy governs the manner in which
                reptileindia.co.in initiate the refund upon your request for
                PRODUCT NAME. This privacy policy applies to this software
                service offered by www.reptileindia.co.in, which is owned and
                operated by Carecone Technologies Private Limited.
              </p>
              <p className="about-text">
                At Reptile, we take pride in the services delivered by us and
                guarantee your satisfaction with our services and support. We
                constantly improve and strive to deliver the best software
                solutions to you. However, in case you are not satisfied with
                our services, please contact us immediately and we will correct
                the situation, provide a refund or offer credit that can be used
                for future Reptile orders.
              </p>
              <p className="about-text">Not Satisfied? Let us know!</p>
              <p className="about-text">
                If you're not satisfied with the service, simply contact us at
                info@reptileindia.co.in to initiate the refund process.
              </p>
              <h6>1.REFUND POLICY</h6>
              <p className="about-text">
                When a payment of fee is made to Reptile, the fees paid in
                advance are retained by Reptile in a client book of account.
                Reptile will earn the fees upon working on a client's matter.
                During an engagement, Reptile earns fee at different rates and
                different times depending on the completion of various
                milestones. Refund cannot be provided for earned fee because
                resources and man hours spent on delivering the service are
                non-returnable in nature. Further, we can't refund or credit any
                money paid to government entities, software and security
                companies or to other third parties with a role in processing
                your order. Under any circumstance, Reptile shall be liable to
                refund only upto the fee paid by the client.
              </p>
              <h6>2.PROCEDURE FOR REQUESTING REFUND</h6>
              <p className="about-text">
                Refund request can be initiated by sending mail to
                info@reptileindia.co.in. All refund requests must be made within
                60 days of purchase. Refund request over 60 days will not be
                accepted and only credit will be provided for completion of
                service / change of service. We will complete processing your
                refund request within 4 to 5 weeks of receiving all the
                information required for processing refund like reason for
                refund, bank details for processing request, etc.
              </p>
              <h6>3.FACTORS OUTSIDE OUR CONTROL</h6>
              <p className="about-text">
                We cannot guarantee the results or outcome of your particular
                procedure. Problems beyond our control and are not covered by
                this guarantee or eligible for refund.
              </p>
              <h6> 4.FORCE MAJEURE</h6>
              <p className="about-text">
                {" "}
                Reptile shall not be considered in breach of its satisfaction
                guarantee policy or default under any terms of service, and
                shall not be liable to the Client for any cessation,
                interruption, or delay in the performance of its obligations by
                reason of earthquake, flood, fire, storm, lightning, drought,
                landslide, hurricane, cyclone, typhoon, tornado, natural
                disaster, act of God or the public enemy, epidemic, famine or
                plague, action of a court or public authority, change in law,
                explosion, war, terrorism, armed conflict, labor strike,
                lockout, boycott or similar event beyond our reasonable control,
                whether foreseen or unforeseen (each a "Force Majeure Event").
              </p>
              <h6>5.CANCELLATION FEE</h6>
              <p className="about-text">
                Since we're incurring costs and dedicating time, manpower,
                technology resources and effort to your service or document
                preparation, our guarantee only covers satisfaction issues
                caused by Reptile - not changes to your situation or your state
                of mind. In case you require us to hold the processing of a
                service, we will hold the fee paid until you are ready to
                commence the service.
              </p>
              <p className="about-text">
                Before processing any refund, we reserve the right to make best
                effort to complete the service. In case, you are not satisfied
                with the service, a cancellation fee of 20% + earned fee + fee
                paid to any third party would be applicable. In case of change
                of service, the cancellation fee would not be applicable.
              </p>
              <div className="cta-area">
              <Link to="/contact-us">
                <a
                  className=" btn-solid reveal-start"
                 // href="https://www.reptileindia.co.in/contact-us"
                >
                  Contact Us
                </a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
        <Footer />
        </>
    </Fragment>
  )
}
export default RefundPolicy;